import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./style.css";
import { MainContextProvider } from "./context/MainContext";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import PrivateRoute from "./helpers/PrivateRoute.js";
import keycloak from "./keycloak/keycloak";
import SimulationOption from "./pages/SimulationOptions.js";
import Invitation from "./pages/Invitation.js";
import InvitationAccept from "./pages/InvitationAccept.js";
import NavigationBar from "./components/NavigationBar.js";
import UserHome from "./pages/UserHome.js";
import { Container, GlobalStyles } from "@mui/material";
import ReactGA from "react-ga4";
import { ThemeProvider, createTheme } from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import UserInsights from "./pages/UserInsights.js";
import UserProfile from "./pages/UserProfile.js";
import PersonalityAssessment from "./pages/PersonalityAssessment.js";
import Footer from "./components/Footer.js";
import SimulationPost from "./pages/SimulationPost.js";
import SimulationStart from "./pages/SimulationStart.js";
import PastSimulations from "./pages/PastSimulations.js";
import SimulationContextProvider from "./context/SimulationContext.js";
import Simulation from "./pages/Simulation.js";
import SimulationRating from "./pages/SimulationRating.js";
import SimulationCombination from "./pages/SimulationCombination.js";
import SimulationInsights from "./pages/SimulationInsights.js";
import SimulationInsightsRating from "./pages/SimulationInsightsRating.js";

ReactGA.initialize("G-890SQ3FNR9");

const root = createRoot(document.getElementById("app"));

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    h4: {
      marginBottom: "10px",
    },
    h5: {
      marginBottom: "10px",
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // currently, you need to provide all of the default mapping because it will replace, not merge.
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
        },
      },
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <ScopedCssBaseline enableColorScheme sx={{ minHeight: "100vh" }}>
      <ReactKeycloakProvider authClient={keycloak}>
        <StrictMode>
          <MainContextProvider>
            <Router>
              <NavigationBar></NavigationBar>
              <Container maxWidth="xl" height="100%">
                <Routes>
                  <Route path="/" element={<UserHome />} />
                  <Route
                    path="/invitation/accept/:invitationCode"
                    Component={InvitationAccept}
                  />

                  <Route path="/start" Component={SimulationStart}></Route>
                </Routes>

                <PrivateRoute>
                  <Routes>
                    <Route
                      exact
                      path="/simulation/combinations/"
                      Component={SimulationCombination}
                    />

                    <Route
                      exact
                      path="/simulation/optionsdetails/"
                      Component={SimulationOption}
                    />

                    <Route
                      exact
                      path="/simulation/options/"
                      Component={SimulationCombination}
                    />
                    <Route
                      exact
                      path="/simulation/past/"
                      Component={PastSimulations}
                    />

                    <Route path="/home/" Component={UserHome} name="Home" />

                    <Route exact path="/invitation/" Component={Invitation} />

                    <Route
                      exact
                      path="/user/:userid/insights"
                      Component={UserInsights}
                    />
                    <Route
                      exact
                      path="/user/profile"
                      Component={UserProfile}
                    ></Route>
                    <Route
                      exact
                      path="/personality/assessment"
                      Component={PersonalityAssessment}
                    ></Route>
                  </Routes>

                  <SimulationContextProvider>
                    <Routes>
                      <Route
                        path="/new/sim/:chatid"
                        Component={Simulation}
                        name="NewSimulation"
                      ></Route>
                      <Route
                        path="/new/sim/:chatid/rating"
                        Component={SimulationRating}
                        name="SimulationRating"
                      ></Route>
                      <Route
                        path="/new/sim/:chatid/insights"
                        Component={SimulationInsights}
                        name="SimulationInsights"
                      ></Route>
                      <Route
                        path="/new/sim/:chatid/insights-rating"
                        Component={SimulationInsightsRating}
                        name="SimulationInsightsRating"
                      ></Route>
                    </Routes>
                  </SimulationContextProvider>
                </PrivateRoute>
              </Container>
              <Footer></Footer>
            </Router>
          </MainContextProvider>
        </StrictMode>
      </ReactKeycloakProvider>
    </ScopedCssBaseline>
  </ThemeProvider>
);
