import axios from "axios";
import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { List, ListItem, Button } from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";
import { Card } from "@mui/material";
import CombinationInstruction from "../components/CombinationInstruction";
import { ChartBarIcon } from "@heroicons/react/16/solid";
import { Navigate } from "react-router-dom";

function SimulationCombinations() {
  const [listCombinations, setListCombinations] = useState({});
  const [chatUrl, setChatUrl] = useState("");

  function launchSimulation(combination) {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/simulation/combination", {
        combination: combination,
      })
      .then((response) => {
        const simulation = response.data;
        setChatUrl("/new/sim/" + simulation.chatid);
      })
      .catch(() => {});
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/simulations/combinations")
      .then((response) => {
        setListCombinations(response.data);
      });
  }, []);

  if (listCombinations == {}) {
    return <h2>Loading ...</h2>;
  }

  if (chatUrl != "") {
    return <Navigate to={chatUrl}></Navigate>;
  }
  return (
    <>
      <Typography color="primary" variant="h4" component="h2" padding={2}>
        Choose a skill to practice
      </Typography>

      {Object.keys(listCombinations).map((s, index) => (
        <Box mb={3}>
          <Card elevation={3}>
            <Box padding={3}>
              <Box sx={{ width: "100%" }}>
                <Typography variant="h5" component="h2" color="primary">
                  <SubjectIcon></SubjectIcon>
                  {listCombinations[s].title}
                </Typography>
              </Box>
              <CombinationInstruction
                s={listCombinations[s]}
                opened={false}
              ></CombinationInstruction>
            </Box>
            <Box padding={3} mb={3}>
              <Button
                margin={3}
                sx={{ display: "float", float: "right" }}
                variant="contained"
                onClick={() => {
                  launchSimulation(s);
                }}
              >
                Start Conversation
              </Button>
            </Box>
          </Card>
        </Box>
      ))}
    </>
  );
}

export default SimulationCombinations;
