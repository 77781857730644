import { Typography, Box, Button, List, ListItem } from "@mui/material";
import { useState } from "react";

function CombinationInstruction({ s, opened = true }) {
  const [isOpen, setIsOpen] = useState(opened);
  function convertToTitleCase(str) {
    if (!str) {
      return "";
    }
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }

  return (
    <Box>
      <Typography>
        <Box mb={2} mt={3}>
          <b>Your Goal : </b> {s.goal}
        </Box>
      </Typography>

      <Typography>
        <Box mb={2}>
          <b>Scenario : </b> {s.about_scenario}
        </Box>
      </Typography>
      {!isOpen && (
        <>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
          >
            ... Read More
          </Button>
        </>
      )}
      {isOpen && (
        <>
          <Typography>
            <Box mb={2}>
              <b>More about {convertToTitleCase(s.persona_name)}: </b>{" "}
              {s.about_persona}
            </Box>
            <b>Recommended steps for your conversation : </b>
          </Typography>
          <Typography>
            <Box paddingLeft={2}>
              <List sx={{ listStyleType: "disc" }}>
                {s.instruction_sections.map((i, index) => (
                  <ListItem sx={{ display: "list-item" }} padding={0}>
                    {i.description}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Typography>
          <Box mt={2}>
            <Typography>
              <b>Be intentional in your conversation</b>, the value of this
              practice lies in your interactions.
            </Typography>
            <Typography>
              At least <b>5 interactions are required</b> to generate meaningful
              insights.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default CombinationInstruction;
