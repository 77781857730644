import { Typography, Box, Grid, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { SimulationContext } from "../context/SimulationContext";
import { Button } from "@mui/material";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Rating, TextField } from "@mui/material";

function SimulationInsightsRating() {
  const { chatid } = useParams();
  const { history, loadHistory, cleanHistory } = useContext(SimulationContext);
  const [go, setGo] = useState(0);
  const { keycloak } = useKeycloak();
  const [ratingValue, setRatingValue] = useState(history.insightRating);
  const [ratingMessage, setRatingMessage] = useState("");
  const [sentComment, setSentComment] = useState(0);

  function sendRatingMessage() {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/comment/" +
          "insight",
        { value: ratingMessage }
      )
      .then((response) => {});
  }

  function changeRating(newValue) {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/rate/" +
          "insight",
        { value: newValue }
      )
      .then((response) => {
        setRatingValue(newValue);
      });
  }

  function redoSim() {
    if (history.combination !== undefined) {
      axios
        .post(process.env.REACT_APP_API_URL + "/api/simulation/combination", {
          headers: { Authorization: "Bearer " + keycloak.token },
          combination: history.combination,
        })
        .then((response) => {
          setGo("/new/sim/" + response.data.chatid);
        });
    } else {
      axios
        .post(process.env.REACT_APP_API_URL + "/api/simulation/", {
          headers: { Authorization: "Bearer " + keycloak.token },
          persona: history.persona,
          subject: history.subject,
        })
        .then((response) => {
          setGo("/new/sim/" + response.data.chatid);
        });
    }
  }

  useEffect(() => {
    loadHistory(chatid);
  }, []);

  useEffect(() => {
    setRatingMessage(history.insightComment);
    setRatingValue(history.insightRating);
  }, [history]);

  if (history.loaded == false) {
    return (
      <Box padding={3}>
        <CircularProgress></CircularProgress>
      </Box>
    );
  }
  if (go) {
    return <Navigate to={go}></Navigate>;
  }
  return (
    <Box padding={3}>
      <Typography variant="h5" component="h2" color="primary">
        {" "}
        Rate the Insights
      </Typography>
      <Box sx={{ minHeight: "500px" }}>
        <Rating
          defaultValue={history.insightRating}
          value={ratingValue}
          onChange={(event, newvalue) => {
            changeRating(newvalue);
          }}
        ></Rating>{" "}
        <Box mt={3}>Why did you give this rating - tell us more:</Box>
        <Box>
          <TextField
            sx={{ width: "50%" }}
            multiline
            rows={8}
            value={ratingMessage}
            onChange={(event) => {
              setRatingMessage(event.target.value);
            }}
          ></TextField>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Button
          style={{ float: "right" }}
          onClick={() => {
            sendRatingMessage();
            setGo("/simulation/options");
          }}
        >
          Start another simulation
        </Button>
        <Button
          sx={{ float: "right" }}
          onClick={() => {
            sendRatingMessage();
            redoSim();
          }}
          variant="contained"
        >
          Redo This Simulation
        </Button>
      </Box>
    </Box>
  );
}

export default SimulationInsightsRating;
