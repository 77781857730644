import { useKeycloak } from "@react-keycloak/web";
import { DeepChat } from "deep-chat-react";
import { Box, Typography } from "@mui/material";
import { SimulationContext } from "../context/SimulationContext";
import { useContext, useState, useEffect } from "react";

function SimulationChat({ history, setHasNewMessage, disable = false }) {
  const { keycloak } = useKeycloak();
  const [localhistory, setLocalHistory] = useState({ chatid: "", history: [] });
  const [inputAreaStyle, setInputAreaStyle] = useState({});

  useEffect(() => {
    if (
      (history.loaded && localhistory.length < history.history.length) ||
      history.chatid != localhistory.chatid
    ) {
      setLocalHistory({ chatid: history.chatid, history: history.history });
    }
    if (disable == true) {
      setInputAreaStyle({ ...inputAreaStyle, display: "none" });
    }
  }, [history]);

  if (!history.loaded) return <></>;

  return (
    <>
      <Box sx={{ width: "100%", display: "flex" }}>
        <DeepChat
          id="maindeepchat"
          demo={true}
          speechToText='{"webSpeech": {"language": "en-US"}, "submitAfterSilence": 200}'
          style={{
            borderRadius: "10px",
            width: "100%",
            height: "600px",
          }}
          connect={{
            url: process.env.REACT_APP_API_URL + "/api/chat/message",
            additionalBodyProps: {
              token: keycloak.token,
              chatid: history.chatid,
            },
          }}
          inputAreaStyle={inputAreaStyle}
          onMessage={(message) => {
            if (message.isHistory || message.isInitial) {
              return;
            }
            localhistory.history.push(message.message);
            setLocalHistory(localhistory);
          }}
          textInput={{ placeholder: { text: "..." } }}
          history={localhistory.history}
        />
      </Box>
    </>
  );
}

export default SimulationChat;
